import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
// import { EventSourcePolyfill } from "event-source-polyfill";
// import { decryptedTokenAccess, IsLoggedIn } from "@/store";

export interface SSE {
  sseData: unknown;
}

@Module
export default class SSEModule extends VuexModule implements SSE {
  sseData = {} as SSE;

  @Mutation
  [Mutations.MUT_START_SSE]() {
    // //console.log("TOKEN=", decryptedTokenAccess());
    // const source = new EventSourcePolyfill(process.env.VUE_APP_URL_SSE, {
    //   withCredentials: false,
    //   headers: {
    //     Authorization: `Bearer ${decryptedTokenAccess()}`,
    //   },
    // });
    //
    // source.addEventListener("message", (event) => {
    //   //console.log("data_on_message=", event.data);
    //
    //   // Store
    //   this.sseData = JSON.parse(event.data);
    //
    //   //localStorage
    //   localStorage.setItem("sse-data", event.data);
    //   window.dispatchEvent(
    //     new CustomEvent("sse-data-localstorage-changed", {
    //       detail: {
    //         storage: localStorage.getItem("sse-data"),
    //       },
    //     })
    //   );
    //
    //   IsLoggedIn().then((isUserConnected) => {
    //     if (!isUserConnected) {
    //       source.close();
    //     }
    //   });
    // });
  }

  @Action
  [Actions.ACTION_LOAD_SSE_DATA]() {
    return this.sseData;
  }
}
