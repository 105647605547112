enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",

  //   ___________ _____  _____  _____
  // /  ___| ___ \  ___||  _  ||  _  |
  // \ `--.| |_/ /___ \ | |/' || |/' |
  //  `--. \  __/    \ \|  /| ||  /| |
  // /\__/ / |   /\__/ /\ |_/ /\ |_/ /
  // \____/\_|   \____/  \___/  \___/

  ACTION_LOAD_DATA_SP500 = "actionLoadDataSP500",
  ACTION_LOAD_SSE_DATA = "actionLoadSSeData",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",

  //   ___________ _____  _____  _____
  // /  ___| ___ \  ___||  _  ||  _  |
  // \ `--.| |_/ /___ \ | |/' || |/' |
  //  `--. \  __/    \ \|  /| ||  /| |
  // /\__/ / |   /\__/ /\ |_/ /\ |_/ /
  // \____/\_|   \____/  \___/  \___/

  MUT_SAVE_DATA_SP500 = "mutSaveDataSP500",
  MUT_START_SSE = "mutStartSSE",
  MUT_STOP_SSE = "mutStopSSE",
}

export { Actions, Mutations };
