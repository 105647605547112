import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export interface SP500 {
  data: unknown;
}

@Module
export default class SP500Module extends VuexModule implements SP500 {
  data = {} as SP500;

  @Mutation
  [Mutations.MUT_SAVE_DATA_SP500](data) {
    this.data = data;
  }

  @Action
  [Actions.ACTION_LOAD_DATA_SP500]() {
    return this.data;
  }
}
