import { createApp } from "vue";

import App from "./App.vue";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import VueSSE from "vue-sse";
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import firebase from "firebase/compat/app";

//imports for app initialization

import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";

//PROD
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_APIKEY,
  authDomain: process.env.VUE_APP_FB_APIKEY,
  projectId: process.env.VUE_APP_FB_PROJECTID,
  storageBucket: process.env.VUE_APP_FB_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDERID,
  appId: process.env.VUE_APP_FB_APPID,
  measurementId: process.env.VUE_APP_FB_MEASUREMENTID,
};

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
app.use(VueSSE);

const VueTelInputOptions = {
  mode: "international",
  onlyCountries: ["FR"],
};
app.use(VueTelInput, VueTelInputOptions);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.config.unwrapInjectedRef = true;
firebase.initializeApp(firebaseConfig);

app.mount("#app");
