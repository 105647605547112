import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import axios from "axios";
import CryptoJS from "crypto-js";
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import SP500Module from "@/store/modules/SP500Module";
import SSEModule from "@/store/modules/sse_data";
// import { EventSourcePolyfill } from "event-source-polyfill";

config.rawError = true;

// TODO TODO TODO
// clé de cryptage dans le stockage local
const Iv = process.env.VUE_APP_JWT_IV;
const Key = process.env.VUE_APP_JWT_KEY;

//SSE
const ssex_data = 0;

//    ___   _    _   _____      _____   _____  ______   _   _   _____   _____   _____
//   |_  | | |  | | |_   _|    /  ___| |  ___| | ___ \ | | | | |_   _| /  __ \ |  ___|
//     | | | |  | |   | |      \ `--.  | |__   | |_/ / | | | |   | |   | /  \/ | |__
//     | | | |/\| |   | |       `--. \ |  __|  |    /  | | | |   | |   | |     |  __|
// /\__/ / \  /\  /   | |      /\__/ / | |___  | |\ \  \ \_/ /  _| |_  | \__/\ | |___
// \____/   \/  \/    \_/      \____/  \____/  \_| \_|  \___/   \___/   \____/ \____/

const saveToken = (tokenKey: string, tokenValue: string): void => {
  localStorage.setItem(tokenKey, tokenValue);
};

const getToken = (tokenKey: string): string => {
  const tokenValue = localStorage.getItem(tokenKey);
  if (tokenValue != null) {
    return tokenValue;
  } else {
    return "";
  }
};

const destroyToken = (tokenKey: string): void => {
  localStorage.removeItem(tokenKey);
};

export const decryptedTokenAccess = (): string => {
  if (getToken("access_token") != null) {
    return decrypted(getToken("access_token"));
  }
  return "";
};

export const decryptedTokenRefresh = (): string => {
  if (getToken("refresh_token") != null) {
    const token = getToken("refresh_token");
    return decrypted(token);
  }
  return "";
};

const deactivateToken = async () => {
  await axios({
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${decryptedTokenAccess()}`,
    },
    url: process.env.VUE_APP_URL_FB_LOGOUT,
  })
    .then(() => {
      //console.log("OK: Deactivate");
      //console.log("<-- deactivate the old token --> ", response);
    })
    .catch(() => {
      //console.log("error error ", error);
    });
};

const refreshToken = async () => {
  firebase
    .auth()
    .currentUser?.getIdToken()
    .then((token) => {
      // console.log(token);
      const tokenRefresh = decryptedTokenRefresh();
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: { userToken: token, refreshToken: tokenRefresh },
        url: process.env.VUE_APP_URL_FB_REFRESH,
      })
        .then(async (response) => {
          // console.log("<--- new Token ---> ", response.data);
          await saveToken(
            "access_token",
            encrypted(response.data["access_token"])
          );
          await saveToken(
            "refresh_token",
            store.state.encrypted(response.data["refresh_token"])
          );
        })
        .catch(() => {
          // console.log("error error ", error);
        });
    })
    .catch((error) => {
      console.log(error);
    });
};

const getPairToken = async () => {
  firebase
    .auth()
    .currentUser?.getIdToken()
    .then((token) => {
      // console.log("token=", token);
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: process.env.VUE_APP_URL_FB_SIGNIN,
        data: { userToken: token },
      })
        .then((response) => {
          // console.log("ACCESS_TOKEN=", response.data);
          // console.log(
          //   "process.env.VUE_APP_URL_FB_SIGNIN=",
          //   process.env.VUE_APP_URL_FB_SIGNIN
          // );
          store.state.saveToken(
            "access_token",
            store.state.encrypted(response.data["access_token"])
          );
          store.state.saveToken(
            "refresh_token",
            store.state.encrypted(response.data["refresh_token"])
          );
        })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.log(error);
    });
};

//   ___    _   _   _____   _   _        _____   _____  ______   _   _   _____   _____   _____
//  / _ \  | | | | |_   _| | | | |      /  ___| |  ___| | ___ \ | | | | |_   _| /  __ \ |  ___|
// / /_\ \ | | | |   | |   | |_| |      \ `--.  | |__   | |_/ / | | | |   | |   | /  \/ | |__
// |  _  | | | | |   | |   |  _  |       `--. \ |  __|  |    /  | | | |   | |   | |     |  __|
// | | | | | |_| |   | |   | | | |      /\__/ / | |___  | |\ \  \ \_/ /  _| |_  | \__/\ | |___
// \_| |_/  \___/    \_/   \_| |_/      \____/  \____/  \_| \_|  \___/   \___/   \____/ \____/

export const IsLoggedIn = async () => {
  try {
    await new Promise((resolve, reject) =>
      firebase.auth().onAuthStateChanged(
        (user) => {
          if (user) {
            // Yes User is signed in.
            resolve("User is there");
          } else {
            // No user is not signed in.
            reject("There is no user");
          }
        },
        // Prevent console errors
        (error) => reject(error)
      )
    );
    return true;
  } catch (error) {
    return false;
  }
};

const IsEmailValidated = async () => {
  return firebase.auth().currentUser?.emailVerified;
};

//  _   _   _____   _____   _       _____
// | | | | |_   _| |_   _| | |     /  ___|
// | | | |   | |     | |   | |     \ `--.
// | | | |   | |     | |   | |      `--. \
// | |_| |   | |    _| |_  | |____ /\__/ /
//  \___/    \_/    \___/  \_____/ \____/

const encrypted = (text: string): string => {
  const encryptedText = CryptoJS.AES.encrypt(
    text,
    CryptoJS.enc.Utf8.parse(Key),
    {
      iv: CryptoJS.enc.Utf8.parse(Iv),
      mode: CryptoJS.mode.CBC,
    }
  );
  return encryptedText.toString();
};

const decrypted = (text: string): string => {
  const decryptedText = CryptoJS.AES.decrypt(
    text,
    CryptoJS.enc.Utf8.parse(Key),
    {
      iv: CryptoJS.enc.Utf8.parse(Iv),
      mode: CryptoJS.mode.CBC,
    }
  );
  return CryptoJS.enc.Utf8.stringify(decryptedText);
};

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    SP500Module,
    SSEModule,
  },
  state: {
    ssex_data,
    saveToken,
    destroyToken,
    encrypted,
    decryptedTokenAccess,
    deactivateToken,
    refreshToken,
    IsLoggedIn,
    IsEmailValidated,
    getPairToken,
    decrypted,
  },
  getters: {
    getSSEXData() {
      return ssex_data;
    },
  },
  actions: {},

  mutations: {
    SSEincrement(state) {
      state.ssex_data++;
    },
  },
});

export default store;
